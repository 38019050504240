<script setup lang="ts">
import { ref } from 'vue'

import { animate } from 'motion'
import type { VoixLinkFieldInterface, VoixMediaFieldInterface, VoixTextFieldInterface, VoixTextareaFieldInterface } from '@voix/types'
import { defineSlice, onMounted } from '#imports'

const props = defineProps<{
  fields: {
    subtitle: VoixTextFieldInterface
    title: VoixTextFieldInterface
    description: VoixTextareaFieldInterface
    link: VoixLinkFieldInterface
    image: VoixMediaFieldInterface
    authorPicture: VoixMediaFieldInterface
  }
}>()

defineSlice({
  name: {
    label: 'Circle Slideshow',
    group: 'Slices',
  },
  description: 'A circle slideshow',
  fields: {
    subtitle: {
      type: 'text',
      label: 'Subtitle',
      default: 'When Traveling',
      enabled: true,
    },
    title: {
      type: 'text',
      label: 'Title',
      default: 'Travel Default',
    },
    description: {
      type: 'textarea',
      label: 'Description',
      default: 'This is the default value',
      enabled: true,
    },
    link: {
      type: 'link',
      label: 'Link',
      enabled: false,
    },
    image: {
      type: 'media',
      label: 'Image',
      breakpoints: {
        default: {
          width: 600,
        },
        md: {
          width: 800,
        },
        lg: {
          width: 1200,
        },
      },
      default: 'https://images.unsplash.com/photo-1610296669228-602fa827fc1f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1550&q=80',
    },
    authorPicture: {
      type: 'media',
      label: 'Author Picture',
      breakpoints: {
        default: {
          width: 300,
          height: 300,
        },
      },
      default: 'https://images.unsplash.com/photo-1610296669228-602fa827fc1f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1550&q=80',
    },
  },
  templates: [
    {
      label: 'Template 1',
      fields: {
        subtitle: {
          value: 'Template 1 Subtitle',
        },
        image: {
          value: 'https://images.unsplash.com/photo-1683009427041-d810728a7cb6?q=80&w=3086&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        },
        link: {
          enabled: true,
          value: {
            href: 'https://www.google.com',
            target: '_blank',
            text: 'Your Link Master',
          },
        },
      },
    },
    {
      label: 'Template 2',
      fields: {
        subtitle: {
          value: 'Template 2 Subtitle',
        },
      },
    },
    {
      label: 'Template 3',
      fields: {
        subtitle: {
          enabled: false,
        },
      },
    },
  ],
})

const animationLength = 10000
const airplanePath = ref(null)

onMounted(() => {
  if (airplanePath.value === null)
    return

  animate(airplanePath.value, { rotate: 360 }, { duration: 5, repeat: Number.POSITIVE_INFINITY, easing: 'linear' })
})
</script>

<template>
  <div class="font-sans lg:container h-[75vh] md:min-h-[800px] mx-auto text-slate-800 px-4 mb-24">
    <div class="relative w-full h-[75vh] md:min-h-[800px] rounded-b-2xl overflow-hidden dark:border-slate-700 dark:border-2">
      <!-- TODO: Add example to documentation -->
      <div class="absolute z-0 inset-0">
        <div class="absolute z-10 inset-0 bg-gradient-to-t from-black/90 via-black/50 to-black/90" />
        <VoixMedia :field="props.fields.image" class="w-full h-full" />
      </div>

      <div class="flex pointer-events-none absolute inset-0 z-20 justify-end items-end md:m-8">
        <div
          class="hidden md:flex absolute z-10 inset-0 w-full mx-auto flex-col justify-start md:justify-end items-center md:items-end"
        >
          <div class="flex flex-col justify-center items-center mt-8 lg:mt-0">
            <div class="relative flex flex-col items-center">
              <div class="relative w-[300px] sm:w-[400px] h-[200px] lg:h-[200px] sm:mt-12 lg:mt-0">
                <div>
                  <div class="absolute inset-0 rounded-lg bg-white dark:bg-slate-700 dark:text-slate-100 px-6 py-3 pb-6 mb-3 font-medium">
                    <div class="mt-4 flex flex-row sm:space-x-8">
                      <div class="hidden sm:block">
                        <div class="w-16 h-16 rounded-full overflow-hidden">
                          <VoixMedia :field="fields.authorPicture" class="w-full h-full" />
                        </div>
                        <div class="leading-tight">
                          <div class="font-medium">
                            Captain Kirk
                          </div>
                          <div class="text-xs text-slate-400">
                            Federated Space
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="flex justify-between items-end pb-2 mb-2 border-b border-slate-200">
                          <div class="text-xs font-bold uppercase">
                            Deep Space
                          </div>
                          <div class="flex ml-8">
                            <StarIcon class="w-5 h-5 text-brand-orange" />
                            <StarIcon class="w-5 h-5 text-brand-orange" />
                            <StarIcon class="w-5 h-5 text-brand-orange" />
                            <StarIcon class="w-5 h-5 text-brand-orange" />
                          </div>
                        </div>
                        <p class="max-w-xs font-light text-sm">
                          Space, the final frontier. These are the voyages of the starship Enterprise. It's mission: to boldly go where no one has gone before.
                        </p>
                      </div>
                    </div>
                    <div class="absolute bottom-0 left-0 right-0 -mb-6 flex justify-center">
                      <svg viewBox="0 0 500 500" class="w-8 h-8 text-white">
                        <polygon fill="currentColor" points="250,300 0,0 500,0" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="relative -mb-2">
                <span class="flex h-3 w-3">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-brand-orange opacity-75" />
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-brand-orange" />
                </span>
              </div>
            </div>

            <div class="relative">
              <div class="rotate-[0deg] transform">
                <div ref="airplanePath" class="h-32 w-32 border-2 border-white/50 border-dashed rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.849 30.849"
                    class="text-white rotate-[135deg] transform -mt-3 ml-12 w-5 h-5"
                  >
                    <path
                      d="M25.707,21.207,20.2,15.7l13.22-9.916L29.563,1.928,13.038,8.539l-5.2-5.2c-1.5-1.5-3.594-1.859-4.654-.8s-.7,3.155.8,4.654l5.2,5.2L2.571,28.92l3.856,3.856,9.917-13.221,5.508,5.508v7.712h3.856l1.928-5.784,5.784-1.928V21.207H25.707Z"
                      transform="translate(-2.571 -1.928)" fill="currentColor"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="absolute inset-0 z-0">
        <div class="pointer-events-none relative z-0 bg-brand-navy/50 w-full h-full flex flex-col justify-end">
          <div class="flex absolute inset-0 z-40 flex-col justify-start items-center">
            <div
              class="mt-[10%] h-[300px] w-[300px] md:h-[400px] md:w-[400px] xl:h-[500px] xl:w-[500px] border-2 rounded-full overflow-hidden"
              :animate="{ opacity: 1 }" :transition="{ duration: 5, easing: 'ease-out' }"
            >
              <VoixMedia :field="props.fields.image" class="object-cover w-full h-full" />
            </div>
          </div>

          <div class="relative z-50 flex flex-col text-left items-start px-8 lg:px-16 mb-12 lg:mb-24 max-w-xl">
            <div v-if="fields.subtitle.enabled" class="text-brand-orange uppercase text-stone-200">
              {{ props.fields.subtitle.value }}
            </div>
            <div class="flex text-6xl font-extrabold text-brand-orange uppercase">
              {{ props.fields.title.value }}
            </div>
            <VoixWysiwyg :field="fields.description" class="text-stone-300 max-w-xl text-xl font-light" />

            <VoixLink :field="fields.link" class="pointer-events-auto mt-8 bg-slate-500 px-4 py-2 rounded-full text-white" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
